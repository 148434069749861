import {
  parseAuthors,
  parseDate,
  superParser,
  createLink,
  arrayIncludesCaseInsensitive,
  removeStringFromEnding
} from "../utilities/datasetManager";
import { Datasets, userImportedFileDatasetId } from "../components/Datasets";

function arraysAreEqual(array1, array2) {
  return JSON.stringify(array1) === JSON.stringify(array2);
}

function htmlLinksAreEqual(link1, link2) {
  return (
    link1.props !== undefined &&
    link2.props !== undefined &&
    link1.props.href !== undefined &&
    link2.props.href !== undefined &&
    link1.props.href === link2.props.href &&
    link1.props.target !== undefined &&
    link2.props.target !== undefined &&
    link1.props.target === link2.props.target &&
    link1.props.children !== undefined &&
    link2.props.children !== undefined &&
    (link1.props.children === link2.props.children ||
      (Array.isArray(link1.props.children) &&
        !Array.isArray(link2.props.children) &&
        link1.props.children.join("") === link2.props.children) ||
      (Array.isArray(link2.props.children) &&
        !Array.isArray(link1.props.children) &&
        link2.props.children.join("") === link1.props.children))
  );
}

// Test detection of author names within 'Author' fields.

function testAuthorParsing() {
  console.log("%cstarting testAuthorParsing...", "font-weight: bold");

  const tests = [
    {
      title: "Author = blank",
      input: null,
      expected: [],
    },
    {
      title: "Author = whitespace",
      input: " ",
      expected: [],
    },
    // https://wiki.autodesk.com/pages/viewpage.action?spaceKey=~jensenl&title=Prism+Use+Cases+-+Authors
    // This should still return the first name cus imageResolution() will still not find the corresponding avatar for Satwik
    {
      title: "Author = one word",
      input: "Satwik",
      expected: ["Satwik"],
    },
    {
      title: "Author = two words",
      input: "Michael Saunders",
      expected: ["Michael Saunders"],
    },
    {
      title: "Author = three words",
      input: "Andrea Fajardo Valerio",
      expected: ["Andrea Fajardo Valerio"],
    },
    {
      title: "Author = hyphenated last name",
      input: "Angel Huntington-Ortega",
      expected: ["Angel Huntington-Ortega"],
    },
    {
      title: "Author = comma-separated list",
      input: "Lars Jensen, Adam Richardson",
      expected: ["Lars Jensen", "Adam Richardson"],
    },
    {
      title: "Author = space-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a> <a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a> <a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "Author = comma-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a>,<a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a>,<a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "Author = comma+space-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a>, <a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a>, <a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "no delimiter, plain text (not sure how this even works)",
      input: "Emily Tsai Sara Kremer",
      expected: ["Emily Tsai"],
    },
    {
      title: "other delimiters",
      input: "Michael Saunders; Ben Leduc-Mills",
      expected: ["Michael Saunders", "Ben Leduc-Mills"],
    },
    {
      title: "names with special meanings",
      input: "N/A",
      expected: [],
    },
    {
      title: "some names in parens, with other stuff",
      input: "Kyle Shulman (Lisa Seaman Consult)",
      expected: ["Kyle Shulman", "Lisa Seaman"],
    },
    {
      title: "aliases",
      input: "Holly Redahan OR Holly Matthews",
      expected: ["Holly Redahan", "Holly Matthews"],
    },
    {
      title: 'more aliases, no "starts with" match',
      input: "Yedige Tlegenov OR Yedige Tlegenov",
      expected: ["Yedige Tlegenov", "Yedige Tlegenov"],
    },
    {
      title: "more aliases, punctuation",
      input: "Nicole E Carey OR Nicole E. Carey OR Nic Carey",
      expected: ["Nicole E Carey", "Nicole E. Carey", "Nic Carey"],
    },
    {
      title: "other stuff, but no parens",
      input: "Gabe Zentall, Lamin Mansaray consulting",
      expected: ["Gabe Zentall", "Lamin Mansaray"],
    },
    {
      title: "commentary in the name field",
      input: "Christie McAllister, on behalf of Chrissy Charlton/ BMP team",
      expected: ["Christie McAllister", "Chrissy Charlton"],
    },
    {
      title: "commentary in the name field, with parens",
      input: "AEX, led by Prophet (Bre Arder Consulting)",
      expected: ["AEX", "Prophet", "Bre Arder"],
    },
    {
      title: "names of third-party companies	",
      input: "Emerge Interactive, Christie McAllister",
      expected: ["Emerge Interactive", "Christie McAllister"],
    },
    {
      title: "names of third-party companies, with parens",
      input:
        "Christie McAllister, led by Adam Richardson and Shane Chase (Enigma Bureau)",
      expected: [
        "Christie McAllister",
        "Adam Richardson",
        "Shane Chase",
        "Enigma Bureau",
      ],
    },
    {
      title: "comma and other stuff",
      input: "Lisa Seaman, as part of Gateways POD",
      expected: ["Lisa Seaman", "Gateways POD"],
    },
    {
      title: "name in parens (with delimiter too)",
      input: "Joe Kappes, Emily Tsai, (Alix Cohen)",
      expected: ["Joe Kappes", "Emily Tsai", "Alix Cohen"],
    },
    {
      title: 'not always "consulting"',
      input: "Judy Bayne, Lars Jensen assisting",
      expected: ["Judy Bayne", "Lars Jensen"],
    },
    {
      title: "person (company), person",
      input: "Adam Richardson (Enigma Bureau), Christie McAllister",
      expected: ["Adam Richardson", "Enigma Bureau", "Christie McAllister"],
    },
    {
      title: "all kinds of stuff",
      input:
        "Christie McAllister documenting, Diane Li/ BMP team commissioned PwC",
      expected: ["Christie McAllister", "Diane Li"],
    },
  ];

  tests.forEach((test) => {
    const result = parseAuthors([{ entry: test.input }]);
    const passed = arraysAreEqual(result, test.expected);
    logTestResults(passed, test.title, test.input, test.expected, result);
  });

  console.log("%cfinished testAuthorParsing.", "font-weight: bold");
} // end testAuthorParsing

//////////////////////////////////////////////////////////////////////////

// Test detection and HTML tagging of hyperlinks, as described here: https://wiki.autodesk.com/display/~jensenl/Prism+-+Auto-linking

function testAutoLinking() {
  console.log("%cstarting testAutoLinking...", "font-weight: bold");

  const tests = [
    {
      title: "1. One raw link",
      input: "http://www.apple.com",
      expected: [createLink(["http://www.apple.com", "http:/..."])],
    },
    {
      title: "2. One Excel-style link (semicolon separator)",
      input: '=HYPERLINK(""http://www.apple.com"";""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "3. One Excel-style link (comma separator)",
      input: '=HYPERLINK(""http://www.apple.com"",""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "4. One Excel-style link (semicolon separator with whitespace)",
      input: '=HYPERLINK(""http://www.apple.com"" ;  ""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "5. One Excel-style link (comma separator with whitespace)",
      input: '=HYPERLINK(""http://www.apple.com""  ,""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "6. One link surrounded by parens (as in OCTO)",
      input: "(http://www.apple.com)",
      expected: ["(", createLink(["http://www.apple.com", "http:/..."]), ")"],
    },
    {
      title: "7. One Slack-style link",
      input: "<http://www.apple.com|Apple>",
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "8. One raw link with surrounding text",
      input: "Here is a link to the http://www.apple.com web site",
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "http:/..."]),
        " web site",
      ],
    },
    {
      title: "9. One Excel-style link with surrounding text",
      input:
        'Here is a link to the =HYPERLINK(""http://www.apple.com"";""Apple"") web site',
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "Apple"]),
        " web site",
      ],
    },
    {
      title: "10. One Slack-style link with surrounding text",
      input: "Here is a link to the <http://www.apple.com|Apple> web site",
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "Apple"]),
        " web site",
      ],
    },
    {
      title: "11. Two Slack-style links",
      input: "<http://apple.com|Apple> <http://microsoft.com|Microsoft>",
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "12. Two Excel-style links",
      input:
        '=HYPERLINK(""http://apple.com"";""Apple"") =HYPERLINK(""http://microsoft.com"";""Microsoft"")',
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "13. One Excel-style link and one Slack-style link",
      input:
        '=HYPERLINK(""http://www.apple.com"";""Apple"") <http://microsoft.com|Microsoft>',
      expected: [
        createLink(["http://www.apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "14. One Slack-style link and one raw link",
      input: "<http://apple.com|Apple> http://microsoft.com",
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "http:/..."]),
      ],
    },
    {
      title:
        "15. One Slack-style link with empty text; should see no field value",
      input: "<http://apple.com|>",
      expected: [createLink(["http://apple.com", ""])],
    },
    {
      title:
        "16. One Slack-style link with empty URL; should see linked text with blank target",
      input: "<|Apple>",
      expected: ["Apple"],
    },
    {
      title:
        "17. One Slack-style link with empty URL and empty text; should see no field value",
      input: "<|>",
      expected: [""],
    },
    {
      title:
        "18. One Excel-style link with empty URL; should see linked text with blank target",
      input: '=HYPERLINK("""";""Apple"")',
      expected: ["Apple"],
    },
    {
      title:
        "19. One Excel-style link with empty text; should see no field value",
      input: '=HYPERLINK(""http://www.apple.com"";"""")',
      expected: [createLink(["http://www.apple.com", ""])],
    },
    {
      title:
        "20. One Excel-style link with empty URL and empty text; should see no field value",
      input: '=HYPERLINK("""";"""")',
      expected: [""],
    },
  ];

  tests.forEach((test) => {
    const results = superParser(test.input).filter((element) => element !== "");
    let passed = true;
    for (let currentIndex = 0; currentIndex < results.length; currentIndex++) {
      if (
        results[currentIndex] === undefined ||
        test.expected[currentIndex] === undefined
      ) {
        // console.log(`expected array element doesn't exist at index ${currentIndex}`);
        passed = false;
        break;
      }
      if (
        safeIsString(results[currentIndex]) &&
        safeIsString(test.expected[currentIndex])
      ) {
        // console.log('both are strings ...');
        if (results[currentIndex] !== test.expected[currentIndex]) {
          // console.log(`strings don't match at index ${currentIndex}`);
          passed = false;
          break;
        }
      } else if (
        !htmlLinksAreEqual(results[currentIndex], test.expected[currentIndex])
      ) {
        // console.log(`links don't match at index ${currentIndex}`);
        passed = false;
        break;
      }
    }
    logTestResults(passed, test.title, test.input, test.expected, results);
  });

  console.log("%cfinished testAutoLinking.", "font-weight: bold");
} // end testAutoLinking

//////////////////////////////////////////////////////////////////////////

// Test evaluation of dates from 'Date' fields.

function testDateParsing() {
  console.log("%cstarting testDateParsing...", "font-weight: bold");

  const tests = [
    {
      title: "Date = blank",
      input: "",
      expected: "0000-00-00",
    },
    {
      title: "Date = random stuff",
      input: "NA",
      expected: "0000-00-00",
    },
    {
      title: "Date = 2022_07_04",
      input: "2022_07_04",
      expected: "2022-07-04",
    },
    {
      title: "Date = July 4, 2022",
      input: "July 4, 2022",
      expected: "2022-07-04",
    },
    {
      title: "Date = Jul 2022",
      input: "Jul 2022",
      expected: "2022-07-01",
    },
    {
      title: "Date = 2022",
      input: "2022",
      expected: "2022-01-01",
    },
    {
      title: "Date = Summer 2022",
      input: "Summer 2022",
      expected: "2022-06-01",
    },
    {
      title: "Date = FY22 Q3",
      input: "FY22 Q3",
      expected: "2022-08-01",
    },
    {
      title: "Date = 09/22/2022",
      input: "09/22/2022",
      expected: "2022-09-22",
    },
  ];

  tests.forEach((test) => {
    const result = parseDate(test.input);
    const passed = result === test.expected;
    logTestResults(passed, test.title, test.input, test.expected, result);
  });

  console.log("%cfinished testDateParsing.", "font-weight: bold");
} // end testDateParsing

//////////////////////////////////////////////////////////////////////////

// Scans the Datasets object and flags a warning/error if there is a repeated dataset/collection ID or invalid dataFile fields

function testDatasets() {
  console.log("%cstarting testDatasets...", "font-weight: bold");

  // Check for collection duplicate ids
  const collectionIdsArray = []
  Object.entries(Datasets).forEach(
    (entryArray) => {
      const key = entryArray[0];
      const collection = entryArray[1];
      collectionIdsArray.push(collection.id);
    }
  );
  const collectionUniqueIdsArray = Array.from(new Set(collectionIdsArray));
  logTestResults(
    arraysAreEqual(collectionUniqueIdsArray, collectionIdsArray),
    `Check for unique collection ids`,
    'UniqueCollectionIds',
    collectionUniqueIdsArray,
    collectionIdsArray
  );

  // Checks if the dataset categories are valid
  Object.entries(Datasets).forEach((entryArray) => {
    const categoryKey = entryArray[0];
    const category = entryArray[1];
    // Check collection ids
    logTestResults(
      category.id !== undefined && category.id,
      `Collection "${categoryKey}"; check for id`,
      categoryKey,
      category.id,
      category.id
    );
    // Check for collection datasets duplicate ids
    const datasetIdsArray = Datasets[categoryKey].datasets.map(
      (dataset) => dataset.id
    );
    const datasetUniqueIdsArray = Array.from(new Set(datasetIdsArray));
    logTestResults(
      arraysAreEqual(datasetUniqueIdsArray, datasetIdsArray),
      `Collection "${categoryKey}"; check for unique dataset ids`,
      categoryKey,
      datasetUniqueIdsArray,
      datasetIdsArray
    );
    // Check for collection datasets invalid dataFile
    const datasetInvalidDataFileArray = [];
    Datasets[categoryKey].datasets.forEach((dataset) => {
      if (
        dataset.id !== userImportedFileDatasetId && // Skip user-uploaded entry since that will be null
        (dataset.dataFile === undefined || !dataset.dataFile)
      ) {
        datasetInvalidDataFileArray.push(dataset.id);
      }
    });
    logTestResults(
      datasetInvalidDataFileArray.length === 0,
      `Collection "${categoryKey}"; check for invalid dataset dataFile`,
      categoryKey,
      [],
      datasetInvalidDataFileArray
    );
  });
} // end testDatasets

//////////////////////////////////////////////////////////////////////////

// Test our case-insensitive alternative to array.includes().

function testArrayIncludesCaseInsensitive() {
  console.log(
    "%cstarting testArrayIncludesCaseInsensitive...",
    "font-weight: bold"
  );

  const tests = [
    {
      title: "Blank target string",
      arr: ["One", "Two", "Three"],
      str: "",
      expected: false,
    },
    {
      title: "Empty array",
      arr: [],
      str: "target",
      expected: false,
    },
    {
      title: "Empty array and blank target string",
      arr: [],
      str: "",
      expected: false,
    },
    {
      title: "Array of empty strings and blank target string",
      arr: [""],
      str: "",
      expected: true,
    },
    {
      title: "Empty input string",
      arr: ["One", "Two", "Three"],
      str: "",
      expected: false,
    },
    {
      title: "One match",
      arr: ["One", "Two", "Three"],
      str: "three",
      expected: true,
    },
    {
      title: "Two matches",
      arr: ["One", "Two", "Three"],
      str: "three",
      expected: true,
    },
    {
      title: "No matches",
      arr: ["One", "Two", "Three"],
      str: "four",
      expected: false,
    },
    {
      title: "One partial match",
      arr: ["One", "Two", "Three"],
      str: "EE",
      expected: false,
    },
  ];

  tests.forEach((test) => {
    const result = arrayIncludesCaseInsensitive(test.arr, test.str);
    const passed = result === test.expected;
    logTestResults(
      passed,
      test.title,
      "[" + test.arr + "] contains '" + test.str + "'",
      test.expected,
      result
    );
  });

  console.log(
    "%cfinished testArrayIncludesCaseInsensitive.",
    "font-weight: bold"
  );
} // end testArrayIncludesCaseInsensitive

//////////////////////////////////////////////////////////////////////////

// Test our case-insensitive alternative to array.includes().

function testRemoveHeaderEndings() {
  console.log(
    "%cstarting testRemoveHeaderEndings...",
    "font-weight: bold"
  );

  const tests = [
    {
      title: "This header was 'Expected'; now it should be 'Expected'",
      input: "Expected",
      expected: "Expected",
    },
    {
      title: "This header was 'Expected()'; now it should be 'Expected'",
      input: "Expected()",
      expected: "Expected",
    },
    {
      title: "This header was 'Expected2()'; now it should be 'Expected'",
      input: "Expected2()",
      expected: "Expected",
    },
    {
      title: "This header was 'Expected()2'; now it should be 'Expected()' because order matters",
      input: "Expected()2",
      expected: "Expected()",
    },
    {
      title: "This header was 'Expected*'; now it should be 'Expected'",
      input: "Expected*",
      expected: "Expected",
    },
    {
      title: "This header was 'Expected (comma delimitated)'; now it should be 'Expected'",
      input: "Expected (comma delimitated)",
      expected: "Expected",
    },
  ];

  tests.forEach((test) => {
    const result = removeStringFromEnding(["()", "2", "*", " (comma delimitated)", ""], test.input);
    const passed = result === test.expected;
    logTestResults(passed, test.title, test.input, test.expected, result);
  });

  console.log(
    "%cfinished testArrayIncludesCaseInsensitive.",
    "font-weight: bold"
  );
} // end testArrayIncludesCaseInsensitive

//////////////////////////////////////////////////////////////////////////

// https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
function safeIsString(value) {
  const valueType = Object.prototype.toString.call(value);
  // console.log('safeIsString', valueType, valueType == '[object String]');
  return valueType === "[object String]";
}

//////////////////////////////////////////////////////////////////////////

function logTestResults(passed, title, input, expected, actual) {
  if (passed) {
    console.log("%cPass: " + title, "color: green");
  } else {
    console.log("%cFail: " + title, "color: red; font-weight: bold");
    console.log("  Input:", input);
    console.log("  Expected:", expected);
    console.log("  Actual:", actual);
    /*
    console.log({
      array1: JSON.stringify(actual),
      array2: JSON.stringify(expected)
    });
    */
  }
}

//////////////////////////////////////////////////////////////////////////

function logWarning(message) {
  console.log("%cWarn: " + message, "color: brown");
}

//////////////////////////////////////////////////////////////////////////

function runUnitTests() {
  console.log("%cstarting runUnitTests...", "font-weight: bold");

  testAuthorParsing();
  testDateParsing();
  testAutoLinking();
  testArrayIncludesCaseInsensitive();
  testDatasets();
  testRemoveHeaderEndings();

  console.log("%cfinished runUnitTests.", "font-weight: bold");
} // end runUnitTests

export { runUnitTests };
