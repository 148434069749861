import React, { useEffect, useState } from "react";
import Typography from "@hig/typography";
import NavBar from "./components/NavBar";
import "@hig/fonts/build/ArtifaktElement.css";
import SideNavBar from "./components/SideNavBar";
import {
  getHTMLForDatasetCategories,
  getDatasetsforCategory,
  getFirstDatasetCategory,
  getDatasetById,
  findDatasetIndex,
  findDatasetCategoryIndex,
  getFirstDataset,
  isValidCollection,
  isValidDataset,
  getFirstCollectionWithDatasetId,
  valueOfFirstField,
} from "./utilities/datasetManager";
import { runUnitTests } from "./utilities/testManager";
import { loadDatasetFile, filterRecords, getDataFileData } from "./utilities/tableManager";
import { useDatasetTableStore } from "./store/datasetTable";
import Tabs, { Tab } from "@hig/tabs";
import DatasetView from "./components/DatasetView";
import ProgressBar from "@hig/progress-bar";
import Notifications from "./components/Notifications";
import { useHistory, useLocation } from "react-router-dom";
import { Datasets, userImportedFileDatasetId } from "./components/Datasets";

//to be re-integrated -- if we ever need popup notifications
// const invalidLinkNotification = {
//   title: "",
//   description: "Invalid link",
//   style: "warning",
// };

function App() {
  const history = useHistory();
  const location = useLocation();
  let params = new URLSearchParams(location.search);

  const getDefaultDatasetCategory = () => {
    if (isValidCollection(params.get("d"))) {
      return findDatasetCategoryIndex(params.get("d"));
    }
    // else {
    //   if (params.get("c")) addNotifications(invalidLinkNotification);
    //   else {
    //want 'd' param; look for 'd' inside collections as the id of dataset inside that collection
    const matchingCollection = getFirstCollectionWithDatasetId(params.get("d"));
    //if we find one, return that collection's id (key)
    if (matchingCollection && matchingCollection[0])
      return matchingCollection[0];
    // }
    //if no valid d or c, return first collection
    return getFirstDatasetCategory();
    // }
  };
  const defaultDatasetCategory = getDefaultDatasetCategory();

  //state variable for notifications list; props to expect on notification objs: title, description, (style)
  const [notificationsList, setNotificationsList] = useState([]);

  //to be re-integrated -- if we ever need popup notifications
  // // Helper functions for notification objects
  // const addNotifications = (...notifications) => {
  //   setNotificationsList([...notificationsList, ...notifications]);
  // };

  const removeNotification = (notificationArrayIndex) => {
    setNotificationsList(
      notificationsList.filter((el, i) => i !== notificationArrayIndex)
    );
  };

  const [sideNavIsOpen, setSideNavOpen] = useState(false);

  //state for search string
  const [searchString, setSearchString] = useState(params.get("q") || "");

  //set current collection id; c param is optional in URL
  const [datasetCategory, setDatasetCategory] = useState(() => {
    return defaultDatasetCategory;
  });

  //metadata (from dataset.js) that is rendered after receiving parsed CSV data
  const [dataset, setDataset] = useState(() => {
    if (isValidDataset(defaultDatasetCategory, params.get("d"))) {
      return getDatasetById(params.get("d"));
    } else {
      if (defaultDatasetCategory || params.get("d")) {
        // don't show this notification, it's annoying and unhelpful
        //addNotifications(invalidLinkNotification);
        return getFirstDataset(defaultDatasetCategory);
      }
    }
  });

  //state variable for loading bar
  const [loading, setLoading] = useState(true);

  //state variable for record count
  const [recordCount, setRecordCount] = useState({});

  //state variable to track the active tab index for each collection, not to rely on params.get in findDatasetIndex
  const [activeTabIndices, setActiveTabIndices] = useState({
    [datasetCategory]: findDatasetIndex(datasetCategory, dataset.id),
  }); //{'collection id': index of dataset}

  // state variable holding table contents for the selected dataset collection
  const [datasetEntriesMapped, setDatasetEntriesMapped] = useState({});

  // datasetEntries store methods
  const addTableToStore = useDatasetTableStore((state) => state.addTable);

  //States for selectRowLegacy:
  const [selection, setSelection] = useState({
    image: null,
    selectionX: null,
    selectionY: null,
    selection: null,
  });

  // User-imported file
  const [importedCsvFile, setImportedCsvFile] = useState();

  const collection = getDatasetsforCategory(datasetCategory);

  useEffect(() => {
    //update current params url
    let queryString = "?";
    if (dataset.id) queryString += `d=${dataset.id}`;

    //update params search string
    if (searchString) queryString += `&q=${searchString}`;

    if (queryString !== location.search) history.push(queryString);
  }, [dataset, searchString, datasetCategory]);

  // Getting info icon to show on first load
  useEffect(() => {
    setDataset(
      getDatasetsforCategory(datasetCategory)[
        activeTabIndices[datasetCategory] || 0
      ]
    );
  }, [activeTabIndices, datasetCategory]);

  useEffect(() => {
    //Caching; if datasetEntriesMapped exists at an id, load in its csv
    //datasetEntriesMapped holds onto every dataset from every collection that has been loaded
    const loadCSVs = async (collection) => {
      const arrayData = await Promise.all(collection.map(loadDatasetFile)); //using Promise.all to make sure all datasetEntries are loaded at the same time
      setDatasetEntriesMapped(
        arrayData.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr,
          }), // when promise resolves -> [{}, {}, ...]
          datasetEntriesMapped //each entry is obj with one prop: key = dataset.id, value = headers and records
        )
      );
      // also feeds tables to the zustand data store
      arrayData.forEach((entry) => {
        const key = Object.keys(entry)[0];
        //console.log('arrayData', {entry: entry[key], key: key});
        addTableToStore(key, entry[key]);
      });
      // Setter for loading bar
      setLoading(false);
    };
    loadCSVs(collection);
    selectRowLegacy();
  }, [
    // only update state when page first loads
    collection,
    // or the user imported a new CSV file
    importedCsvFile,
  ]);

  useEffect(() => {
    // Need to setRecordCount for all datasets loaded
    setRecordCount(
      Object.entries(datasetEntriesMapped).reduce(
        (acc, [id]) => ({
          ...acc,
          [id]: filterRecords(
            getDatasetById(id),
            datasetEntriesMapped[id],
            searchString
          ).length,
        }),
        {}
      )
    );
  }, [datasetEntriesMapped, searchString]);

  // autoselects the entry when it's the only entry to select
  useEffect(() => {
    const activeEntry = filterRecords(
      dataset,
      datasetEntriesMapped[dataset.id],
      searchString
    );
    //get filtered array of entries in currently active dataset
    if (activeEntry.length === 1) selectRowLegacy(activeEntry[0]);
  }, [searchString, datasetEntriesMapped]);

  // clears selection when changing datasets (tabs)
  useEffect(() => {
    const activeEntry = filterRecords(
      dataset,
      datasetEntriesMapped[dataset.id],
      searchString
    );
    //get filtered array of entries in currently active dataset
    if (activeEntry.length === 1) selectRowLegacy(activeEntry[0]);
    else selectRowLegacy();
  }, [dataset, datasetEntriesMapped]);

  // Clear search string when collections change
  // useEffect(() => {
  //   if (params.get("c") !== datasetCategory) setSearchString("");
  // }, [datasetCategory]);

  //////////////////////////////////////////////////////////////////////////
  // HandleClicks for SideNavBar

  const toggleSideNavOpen = () => {
    setSideNavOpen(true);
  };

  const toggleSideNavClose = () => {
    setSideNavOpen(false);
  };

  const selectRowLegacy = (row) => {
    const table = datasetEntriesMapped[dataset.id];
    if (!row) {
      setSelection({
        image: null,
        selectionX: null,
        selectionY: null,
        selection: "",
      });
    } else {
      const datasetHeaders = table.headers;
      const Name = valueOfFirstField(row, datasetHeaders, "Name");
      const Number = valueOfFirstField(row, datasetHeaders, "Number");
      const Image = valueOfFirstField(row, datasetHeaders, "Image");
      const X = valueOfFirstField(row, datasetHeaders, "X");
      const Y = valueOfFirstField(row, datasetHeaders, "Y");

      setSelection({
        image: Image,
        selectionX: X ? X : 5, // 5 puts it offscreen,
        selectionY: Y ? Y : -5, // -5 puts it offscreen,
        selection: Number + " " + Name,
      });
    }
  }; // end SelectRowLegacy

  const onLoadInputFile = async (file, jsonFile = null) => {
    if (file) {
      setLoading(true);
      const userCsvImportDatasetMatches = getFirstCollectionWithDatasetId(
        userImportedFileDatasetId
      );
      if (userCsvImportDatasetMatches[0]) {
        const userCsvDatasetCategory = userCsvImportDatasetMatches[0];
        
        // We want to be able to see the data using special formatting.
        // We don't make that the default because it doesn't look good
        // if there is no title field. For now we'll test for a special
        // character prepending the filename. But a better way would be
        // to test for the presence of default titleFields within the file.

        if (file.name[0] === "*") {
          Datasets[userCsvDatasetCategory].datasets[0].specialFormatting = {};
        } else {
          Datasets[userCsvDatasetCategory].datasets[0].specialFormatting = null;
        }

        // PRISM-37: Add formatting (etc) to Import feature
        // merge json with the uploaded file's json, if any
        if (jsonFile) {
          Datasets[userCsvDatasetCategory].datasets[0] = await getDataFileData((jsonFile)).then((json) => JSON.parse(json));
        }
        Datasets[userCsvDatasetCategory].datasets[0].id = userImportedFileDatasetId;
        Datasets[userCsvDatasetCategory].datasets[0].dataFile = file;
        Datasets[userCsvDatasetCategory].datasets[0].hidden = false;
        Datasets[userCsvDatasetCategory].datasets[0].label = file.name;

        setDatasetCategory(userCsvDatasetCategory);
        setImportedCsvFile(file);
      } else {
        setLoading(false);
      }
    }
  };

  /**
   * Wrapper function to change the current dataset
   *
   */
  const switchToDatasetCategory = (newDatasetCategory) => {
    // If the selected category is the user-uploaded CSV, check if a file has been uploaded already
    if (
      Datasets[newDatasetCategory] !== undefined &&
      Datasets[newDatasetCategory].datasets[0].id ===
        userImportedFileDatasetId &&
      (Datasets[newDatasetCategory].datasets[0].dataFile === undefined ||
        Datasets[newDatasetCategory].datasets[0].dataFile === null)
    ) {
      // Prevent changing to this category if no file is present
      return;
    }
    if (newDatasetCategory !== datasetCategory) {
      setLoading(true);
      setDatasetCategory(newDatasetCategory);
    }
  };

  /////////////////////////////////////////////////////////////////////////
  // maps out datasets to their record counts and sums them up per collection
  const totalRecordCount = getDatasetsforCategory(datasetCategory) //collection
    .map((dataset) => recordCount[dataset.id]) //numeric value for each record count
    .reduce((acc, curr) => acc + curr, 0); //sum each dataset's record count

  //////////////////////////////////////////////////////////////////////////
  /* If the user types in enough characters to auto-select a room, and
  that's the room they want, they probably won't do anything else.
  So we track when the selection changes and spit out the room text
  at that point, as our guess for which room the user was looking for. */

  // This should iterate the collection and omit any datasets for
  // which .hidden is true. And if there are no non-hidden datasets in a
  // collection, the collection should not appear in the menu.

  //console.log('Loading:', loading);

  return (
    <Typography
      elementType="div"
      className={sideNavIsOpen ? "App app-nav-open" : "App"}
    >
      {sideNavIsOpen && <SideNavBar />}
      <NavBar
        sideNavIsOpen={sideNavIsOpen}
        toggleSideNavOpen={toggleSideNavOpen}
        toggleSideNavClose={toggleSideNavClose}
        onLoadInputFile={onLoadInputFile}
        datasetCategories={getHTMLForDatasetCategories(
          switchToDatasetCategory,
          datasetCategory
        )} // released collections
        testDatasetCategories={getHTMLForDatasetCategories(
          switchToDatasetCategory,
          datasetCategory,
          true
        )} // experimental collections
        categoryLabel={datasetCategory}
        datasetLabel={dataset.label}
        tabIndex={activeTabIndices} // hack; not in NavBar parameter list
        info={dataset.info}
        totalRecordCount={totalRecordCount}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      {loading && (
        <div className="loader">
          <ProgressBar />
        </div>
      )}
      <Tabs
        className="dataset-tabs"
        activeTabIndex={activeTabIndices[datasetCategory] || 0}
        onTabChange={(index) => {
          setActiveTabIndices({
            ...activeTabIndices,
            [datasetCategory]: index,
          });
        }}
      >
        {collection
          .filter((dataset, i) => !dataset.hidden)
          .map((dataset, i) => (
            <Tab
              key={dataset.id}
              label={
                recordCount[dataset.id] ? (
                  <>
                    <span className="tab-label">{dataset.label}</span>{" "}
                    <span className="tab-count">{recordCount[dataset.id]}</span>
                  </>
                ) : (
                  <>
                    <span className="tab-label-empty">{dataset.label}</span>{" "}
                    <span className="tab-count-empty">--</span>
                  </>
                )
              }
            >
              <DatasetView
                key={dataset.id}
                dataset={dataset}
                searchString={searchString}
                loading={loading}
                selection={selection}
                selectRowLegacy={selectRowLegacy}
              />
            </Tab>
          ))}
      </Tabs>
      <Notifications list={notificationsList} remove={removeNotification} />
    </Typography>
  );
} // end App

window.test = function() {
  runUnitTests();
};

export default App;
