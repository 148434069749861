// This function decides if a given field value matches the search
// string typed in by the user. For now, it simply looks for the
// search string inside the field value, case-insensitive. This is not
// very smart; for example, a double-space between words will fool it.
// In the future, we can do more sophisticated matching, e.g.
// match by word, Soundex, stemming, wildcards, etc.
// hack; convert to use localeCompare

const isMatch = (fieldValue, searchString) => {
  if (searchString.trim() === "") return true;

  const searchRegex = new RegExp(
    `(${sanitizeSearchString(searchString)})`,
    "gi"
  );
  return searchRegex.test(fieldValue);
};

function sanitizeSearchString(searchStr) {
  return searchStr
    .replace(/([*.?[\]|&;$%@"<>()+,])/g, "\\$1")
    .split(" ")
    .filter((el) => el !== "")
    .join("|");
}

export { sanitizeSearchString, isMatch };
