import Papa from "papaparse";
import {
  applyRemoveHeaderEndings,
  applyLinkFields,
} from "../utilities/datasetManager";
import {
  userImportedFileDatasetId
} from "../components/Datasets";
import { isMatch } from "../utilities/searchManager";

const getDataFileDelimeter = (dataset) => 
  typeof dataset.delimiter !== undefined ? dataset.delimiter : ","
;

const getDataFileData = async (file) =>
  typeof file === 'string' ?
    await readUTF8File(file) :
    await new Response(file).text().catch((err) => console.log(err))
;

const sortRecords = (records, headers, dataset) => {
  if (dataset.sortBy === undefined) {
    return records;
  }
  dataset.sortBy.forEach((sortHeader) => {
    const sortHeaderIndex = headers.indexOf(sortHeader);
    if (sortHeaderIndex > -1) {
      records = records.sort((a, b) => {
        if (typeof a[sortHeaderIndex] === 'number' && typeof b[sortHeaderIndex] === 'number') {
          return a[sortHeaderIndex].localeCompare(
            b[sortHeaderIndex],
            null,
            {
              numeric: true
            }
          );
        } else if (typeof a[sortHeaderIndex] === 'number') {
          return 1;
        }
        return a[sortHeaderIndex].localeCompare(b[sortHeaderIndex]);
      });
    }
  });

  return records;
};

/**
 *
 * Loads table contents from CSV file given a dataset
 *
 * @param {*} dataset
 * @returns Promise
 */

const loadDatasetFile = (dataset) => {
  // PRISM-36: Support multiple data files per dataset
  if ( 
    (dataset.isJson === undefined || dataset.isJson === false)
    && Array.isArray(dataset.dataFile) 
  ) {
    return new Promise(async (resolve) => {
      const finalData = await Promise.all(dataset.dataFile.map((fileOptions) => {
        const file = fileOptions.file;
        return new Promise(async (resolve) => 
          Papa.parse(
            await getDataFileData(file), 
            {
              complete: (parsedData) => {
                const _data = parsedData.data.filter((row) => row.some((column) => column.trim() !== ''));
                if (
                  fileOptions.appendColumn !== undefined &&
                  fileOptions.appendColumn.header !== undefined &&
                  fileOptions.appendColumn.value !== undefined
                ) {
                  const fileHeaders = dataset.headers !== undefined ? dataset.headers : _data.shift();
                  const headerIndex = fileHeaders.indexOf(fileOptions.appendColumn.header);
                  if (headerIndex > -1) {
                    _data.map((row) => row[headerIndex] = fileOptions.appendColumn.value);
                  }
                }
                resolve(_data);
              },
              delimiter: getDataFileDelimeter(dataset),
              header: false
            }
          )
        );
      }));
      const headers = dataset.headers !== undefined ? dataset.headers : mergedData.shift();
      let mergedData = [];
      finalData.forEach((_data) => {
        mergedData = mergedData.concat(_data);
      });
      mergedData = mergedData.filter((row) => row.length === headers.length);
      resolve({
        [dataset.id]: applyDataModifiers(dataset, {
          headers: headers,
          records: sortRecords(mergedData, headers, dataset),
        })
      });
    });
  } else {
    return loadCSV(dataset.dataFile, dataset);
  }
}

//Promisify loadCSV to explicitly return a promise
const loadCSV = (csvFile, dataset) => {
  return new Promise(async (resolve) => {
    // TODO - Consider moving this to a loop just for JSON in the future
    if (dataset.isJson) {
      // Convert the json content to string to avoid weird errors on React
      const data = dataset.dataFile.map((row) => {
        const newRow = [];
        row.forEach((column) => {
          newRow.push(
            String(column)
              // convert html href into Excel link Markup (wiki markup is too similar to HTML markup)
              .replace(/<a href="([^>]+)>?([^<]+)<\/a>/g, '=HYPERLINK(""$1"";""$2"")')
              // Add wiki domain to relative wiki links so superParser can recognize it
              .replace('/pages/viewpage.action?pageId', 'https://wiki.autodesk.com/pages/viewpage.action?pageId')
              .replace(/\/display\/([^\/]+\/[^\/]*)/, 'https://wiki.autodesk.com/display/$1')
              // then remove all other tags
              .replace(/<[^>]+>/g, '')
              // clean up
              .replace(/"""/g, '""')
              .trim()
          );
        });
        return newRow;
      });
      resolve({
        [dataset.id]: applyDataModifiers(dataset, {
          headers: data.shift(), // pull and save the headers
          records: data,
        }),
      });
      return;
    }

    const csvData = dataset.id === userImportedFileDatasetId ?
      await new Response(csvFile).text().catch((err) => console.log(err)) :
      await getDataFileData(csvFile)
    ;

    Papa.parse(csvData, {
      // Moving onParsedComplete logic into loadCSV, so we can use loadCSV iteratively

      /* If you tell PapaParse that your file has a header, then it uses
		the header strings as keys. That causes a problem for datasets with
		non-unique headers, which -- while uncommon -- are VALID, and in fact
		we have this very situation with the "All Research Projects" dataset,
		which has 5 "Title" fields and 2 "Role" fields. To get around this,
		we parse our files as if they have no header, and now that parsing
		is complete, we extract the header here and stash it in application
		state. */
      complete: (parsedData) =>
        resolve({
          [dataset.id]: applyDataModifiers(dataset, {
            headers: parsedData.data.shift(), // pull and save the headers
            records: parsedData.data,
          }),
        }),
      delimiter: getDataFileDelimeter(dataset),
      header: false, //see comments in onParseComplete
    }); //parsing csv as text into json obj
  });
};

/**
 *
 * Returns the text contents of CSV file given its path
 *
 * @param {string} path
 * @returns string
 */
const readUTF8File = (path) =>
  fetch(path)
    .then((res) => res.text()) //not using .json() bc we're receiving raw data as csv as 1 string from fetch request

    // Textdecoder wasn't solving the record count discrepancy. Fetched data was already in utf8.
    // Try 1: TypeError; readable undefined
    // let decoder = new TextDecoder("utf-8");
    // return decoder.decode(data);

    // Try 2: No change in functionality as 'return data'
    // return Buffer.from(data, 'utf-8').toString();

    .catch((err) => console.log(err));

/**
 *
 * ADD DATA MODIFIERS HERE
 *
 */
const applyDataModifiers = (dataset, data) => {
  // PRISM-18 - implement removeHeaderEndings for datasets
  let newData = applyRemoveHeaderEndings(dataset, data);
  // PRISM-15 - implement linkFields for datasets
  return applyLinkFields(dataset, newData);
};

/**
 *
 * This returns a chunk of HTML representing the list items that match our filter.
 *
 * @param {Object} dataset
 * @param {Object} table
 * @param {string} searchString
 * @returns Object (filtered table.records)
 */
const filterRecords = (dataset, table, searchString) => {
  if (!table) return [];
  const datasetHeaders = table.headers;
  const datasetRecords = table.records;

  /* Figure out which fields we will search. If the dataset has a fieldsToSearch
	property, we search every field whose name is in that list. Otherwise, we
	search all fields. In the case that a field name occurs multiple times in
	the dataset, we search all instances of that field. */

  const fieldIndicesToSearch = [];
  if (dataset.fieldsToSearch) {
    // custom search: only specified fields
    for (
      let iFilterFld = 0;
      iFilterFld < dataset.fieldsToSearch.length;
      iFilterFld++
    ) {
      for (let iFld = 0; iFld < datasetHeaders.length; iFld++) {
        if (datasetHeaders[iFld] === dataset.fieldsToSearch[iFilterFld]) {
          fieldIndicesToSearch.push(iFld);
        }
      }
    }
  } else {
    // default search: all fields
    for (let iFld = 0; iFld < datasetHeaders.length; iFld++) {
      fieldIndicesToSearch.push(iFld);
    }
  }

  // Split our search string into substrings, delimited by whitespace.
  // Then search each field of each record for each substring.

  // hack; this nearly duplicates sanitizeSearchString
  const subStrings = searchString
    .replace(/([*.?[\]|&;$%@"<>()+,])/g, "\\$1")
    .split(" ")
    .filter((el) => el !== "");

  const matchingRecords = [];
  for (let iRec = 0; iRec < datasetRecords.length; iRec++) {
    let countOfSubstringsFound = 0;
    for (let iStr = 0; iStr < subStrings.length; iStr++) {
      for (let iFld = 0; iFld < fieldIndicesToSearch.length; iFld++) {
        const fldValue = datasetRecords[iRec][fieldIndicesToSearch[iFld]];
        if (isMatch(fldValue, subStrings[iStr])) {
          countOfSubstringsFound++; // we found this substring
          break; // we only need to find each substring once per record
        }
      }
    }

    if (
      subStrings.length === 0 ||
      subStrings.length === countOfSubstringsFound
    ) {
      matchingRecords.push(datasetRecords[iRec]);
    }
  }

  return matchingRecords; //array of array of strings
};

export { loadDatasetFile, filterRecords, getDataFileData };
